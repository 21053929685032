/* ==========================================================================
   1. IMPORT GOOGLE FONTS
   ========================================================================== */

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaLight.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaRegular.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaMedium.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Gordita';
  src: url('../src/website/assets/fonts/GorditaBold.woff2');
  font-weight: 700;
}

/* ==========================================================================
      2. GENERAL
      ========================================================================== */

body {
  font-family: "Gordita", sans-serif !important;
  color: #333;
  font-weight: 300;
  font-size: 14px;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

p {
  line-height: 30px;
  color: #333;
  font-size: 14px;
}

a {
  line-height: 35px;
  color: #333;
}

a:hover, a:focus {
  color: #333;
  text-decoration: none;
  opacity: 0.8;
}

#app-section {
  overflow: hidden;
}

h5 {
  outline: none;
}

.hidden {
  display: none;
}

.menu {
  background-color: #333;
}

.rounded {
  border-radius: 20px !important;
}

.shadow {
  box-shadow: 0px 0px 13px 1px #ccc;
}

.fshadow {
  border-radius: 5px;
  box-shadow: 0px 0px 40px 20px #cccccc36;
}

.bshadow {
  box-shadow: -2px -4px 10px 0px #ccc;
}

.nav-links a {
  color: #333 !important;
  font-weight: nnormal;
}

.navbar-toggler {
  outline: none !important;
  border: none;
}

.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.rm-p {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.rm-pv {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.rm-m {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.br {
  padding-right: 50px;
}

@media screen and (min-width: 600px) {
  #cookie-consent {
    border-top-right-radius: 50px;
    width: 30%;
  }
}

#cookie-consent {
  border-top-right-radius: 50px;
}

#cookie-consent-button {
  background-color: transparent !important;
  border: solid 1px #333;
  color: #333 !important;
  height: 16px;
  line-height: 2px !important;
  font-size: 11px !important;
  font-weight: 500;
  border-radius: 20px;
  margin: 0px 20px;
  padding: 17px 20px !important;
  border-radius: 20px !important;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 3px, rgba(0, 0, 0, 0.24) 0px 0px 2px;
  white-space: nowrap;
  cursor: pointer;
}

.banner {
  margin: 0px !important;
  position: relative;
  background-color: #e4e4e4 !important;
  font-weight: 300 !important;
  padding: 4px 10px !important;
  font-size: 14px !important;
}

.banner a {
  font-weight: 500;
}

.mr-nav {
  margin-right: 30px;
}

.m-auto {
  margin: auto !important;
}

.overflow {
  overflow: hidden;
}

.overflowV {
  overflow: visible;
}

.bold {
  font-weight: 400;
}

.bgWhite {
  background-color: white;
}

.line {
  border-bottom: solid 1px #f5f5f5;
}

.center {
  align-self: center;
  margin: auto;
}

.left {
  align-self: flex-start;
}

.nav-link {
  font-weight: 300;
}

#footer {
  background-color: #f3f3f3;
}

.Collapsible {
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 4px #e4e4e4;
  border-radius: 20px;
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Collapsible span {
  word-wrap: break-word;
  white-space: pre-wrap;
}

ul {
  list-style: none;
  padding-left: 0px !important;
}

ul li {
  padding-bottom: 10px;
  list-style: none;
}

ol li {
  padding-bottom: 10px;
}

@media (max-width: 1000px) {
  .nav-link {
    padding: 20px !important;
    font-size: 1.2em;
    border-bottom: solid 1px #ccc;
  }
  .nav-link:last-child {
    padding: 20px !important;
    border-bottom: none;
  }
  #responsive-navbar-nav {
    margin-top: 50px !important;
  }
}

#card {
  perspective: 30px;
}

#inner {
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  box-shadow: 2px 2px 50px rgba(0, 0, 0, 0.2);
}

#card:hover .demo-overlay {
  opacity: 1;
}

#card:hover {
  cursor: pointer;
}